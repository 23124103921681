<template>
    <div class="bkColor">
        <headTitle :title="title" :routerPath="routerPath"></headTitle>
        <van-form validate-first @failed="onFailed" @submit="submitForm" ref='form'>
            <van-field v-model="projectNo" label="项目编号" placeholder="项目编号提交后自动生成" disabled v-if="!isUpdate" />

            <van-field name="radio" label="项目归属" required>
                <template #input>
                    <van-radio-group v-model="queryFrom.belong" direction="horizontal">
                        <van-radio name="DM">大美自然</van-radio>
                        <van-radio name="ZC">中创科技</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field v-model="queryFrom.year" n label="所属年份" placeholder="请输入所属年份"
                :rules="[{ required: true, message: '请填写所属年份' }, { pattern, message: '请输入正确的年份' },]" required />


            <van-field v-model="queryFrom.projectName" label="项目名称" placeholder="请输入项目名称"
                :rules="[{ required: true, message: '项目名称不能为空' }]" required />
            <selectPerson :title="'项目负责人'" @selectPerson="getPjPerson" :choosePerson="queryFrom.personInCharge">
            </selectPerson>
            <van-field v-model="queryFrom.custom" label="客户" placeholder="请输入"
                :rules="[{ required: true, message: '客户不能为空' }]" required />

            <van-field clickable name="picker" :value="queryFrom.projectTypeName" label="项目类型" placeholder="点击选择项目类型"
                @click="show = true" :rules="[{ required: true, message: '请选择项目类型' }]" required />
            <van-popup v-model="show" position="bottom">
                <van-picker show-toolbar :columns="columns" value-key="label" :loading="loading" @confirm="onConfirm"
                    @cancel="show = false" />
            </van-popup>

            <van-field v-model="queryFrom.projectIntroduction" rows="2" autosize label="项目简介" type="textarea"
                maxlength="800" placeholder="请输入项目简介" show-word-limit :rules="[{ required: true, message: '项目简介不能为空' }]"
                required />

            <van-field v-model="queryFrom.city" is-link readonly label="项目地址" placeholder="请选择省/市/区" @click="showA = true"
                class="address" />
            <van-popup v-model="showA" round position="bottom">
                <van-cascader v-model="cascaderValue" title="请选择所在地区" :options="options" @close="showA = false"
                    @finish="onFinish" />
            </van-popup>
            <van-field v-model="queryFrom.address" label="" placeholder="请输入详细地址" />

            <van-field v-model="queryFrom.price" type="number" label="项目金额（元）" placeholder="请输入项目金额"
                :rules="[{ required: true, message: '项目金额不能为空' }]" required />
            <!-- <van-field v-model="queryFrom.costBudget" type="number" label="成本预算金额（元）" placeholder="请输入成本预算额"
                :rules="[{ required: true, message: '成本预算不能为空' }]" required :disabled="isUpdate" /> -->

            <van-field v-model="costing" type="number" label="成本预算金额（%）" placeholder="请输入成本预算额" required />
            <div :class="[(queryFrom.price && costing) ? 'data' : 'noData']">成本预算额{{
                queryFrom.costBudget }}元</div>

            <van-field v-model="queryFrom.administrationRate" type="number" label="行政费率（%）" placeholder="请输入行政费率" />
            <div :class="[(queryFrom.price && queryFrom.administrationRate) ? 'data' : 'noData']">行政费占{{
                queryFrom.price * queryFrom.administrationRate / 100 }}元</div>

            <van-field v-model="queryFrom.marketRate" type="number" label="市场费率（%）" placeholder="请输入市场费率" />
            <div :class="[(queryFrom.price && queryFrom.marketRate) ? 'data' : 'noData']">市场费占{{
                queryFrom.price * queryFrom.marketRate / 100 }}元</div>
            <van-field v-model="queryFrom.trafficRate" type="number" label="交通费（%）" placeholder="请输入交通费率" />
            <div :class="[(queryFrom.price && queryFrom.trafficRate) ? 'data' : 'noData']">交通费占{{
                queryFrom.price * queryFrom.trafficRate / 100 }}元</div>
            <van-field v-model="queryFrom.taxRate" type="number" label="税率（%）" placeholder="请输入税率" />
            <div :class="[(queryFrom.price && queryFrom.taxRate) ? 'data' : 'noData']">税费占{{
                queryFrom.price * queryFrom.taxRate / 100 }}元</div>
            <van-field v-model="queryFrom.salaryRate" type="number" label="工资费率（%）" placeholder="请输入工资费率" />

            <div :class="[(queryFrom.price && queryFrom.salaryRate) ? 'data' : 'noData']">工资占{{
                queryFrom.price * queryFrom.salaryRate / 100 }}元</div>





            <van-field v-model="queryFrom.startTime" is-link readonly label="项目计划开始日期" placeholder="请选择计划开始时间"
                @click="showB = true" :rules="[{ required: true, message: '项目计划开始日期不能为空' }]" required />
            <van-popup v-model="showB" round position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" title="计划开始日期" @confirm="chooseTime('start')"
                    @cancel="showB = false" />
            </van-popup>
            <van-field v-model="queryFrom.endTime" is-link readonly label="项目计划结束日期" placeholder="请选择计划结束时间"
                @click="showC = true" :rules="[{ required: true, message: '项目计划结束日期不能为空' }]" required />
            <van-popup v-model="showC" round position="bottom">
                <van-datetime-picker v-model="currentDate" type="date" title="计划结束日期" @confirm="chooseTime('end')"
                    @cancel="showC = false" />
            </van-popup>

            <van-field name="uploader" label="附件">
                <template #input>
                    <van-uploader v-model="uploader" @click-preview="viewFile" accept="file" upload-icon="plus"
                        :after-read="afterRead" :before-read="beforeRead" @delete="deleteFile"
                        :preview-full-image="false" />
                </template>
            </van-field>

            <!-- <process v-if="!powerList.includes('1')" @judgeList="getjudgeList" :avatar-list="selectList"
                :routerPath="'/newPj'"></process> -->
            <van-button round block type="info" native-type="submit" :disabled="uploading" :loading="isLoading"
                loading-text="加载中...">提交</van-button>
        </van-form>


    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";

import {
    Field,
    Popup,
    Cascader,
    Picker,
    Uploader,
    DatetimePicker,
    Button,
    Toast,
    Form,
    RadioGroup,
    Radio
} from "vant";
import { getToken } from '../units/auth'
import { areaList } from "../units/area";
import { timeChange } from '../units/units'
import { infoAdd, getInfo, infoPut } from "../api/info";
import { configDetailList } from "../api/configDetail";
import { fileUpload } from '../api/api'
import selectPerson from "../components/selectPerson.vue";


Vue.use(Field);
Vue.use(Popup);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(Uploader)
Vue.use(DatetimePicker);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Form)
Vue.use(Radio);
Vue.use(RadioGroup);

export default {
    name: "newPj",
    components: {
        headTitle,
        selectPerson,
    },
    data() {
        return {
            title: "新建项目信息",
            show: false,
            showA: false,
            showB: false,
            showC: false,
            cascaderValue: "",
            options: areaList, // 选项列表，children 代表子选项，支持多级嵌套
            currentDate: new Date(),
            projectType: "", //显示项目类型文字
            projectNo: "", //项目编号显示文字
            columns: [],
            loading: false,
            pattern: /^(20[1-4]\d|2050)$/,//2010-2050的年份正则
            costing: 60,
            queryFrom: {
                city: "", //城市
                belong: 'DM',//项目归属
                year: '',//所属年份
                projectName: "", //项目名称
                personInCharge: "", //项目负责人
                chargeId: '',//项目负责人id
                custom: "", //客户
                projectType: "", //项目类型
                projectTypeName: "", //项目类型中文配置
                projectNo: "", //项目类型简称
                projectIntroduction: "", //项目简介
                address: "", //详细地址
                price: "", //项目金额
                costBudget: "", //成本金额预算
                startTime: "", //计划开始时间
                endTime: "", //计划结束时间
                // projectReviewedList: [], //审核人列表
                fileList: [],//文件列表
                administrationRate: 5,//行政费率
                marketRate: 5,//市场费率
                trafficRate: 4,//交通费率
                taxRate: 6,//税率
                salaryRate: 10//工资费率
            },
            // tempArr: [],//暂时数组
            // selectList: [
            //     {
            //         value: 999999,
            //         avatar: require("../assets/icon/tj_img.png")
            //     }
            // ],//选择完审核人后的返回
            flag: [],
            powerList: [],//value 1总经理 2财务 3行政
            uploader: [], //附件
            uploading: false, //是否正在上传
            isLoading: false,
            isUpdate: false,
            projectId: '',
            routerPath: '/'
        };
    },
    created() {
        this.getColumn();

    },
    mounted() {
        this.getSecectList()
        this.powerList = JSON.parse(getToken('powerList') || '[]')
        this.getPjDetail()
    },
    activated() {
        this.isUpdate = this.$route.query.projectId ? true : false
        this.projectId = this.isUpdate ? this.$route.query.projectId : '';
        this.routerPath = this.isUpdate ? this.$route.query.route : '/'
        this.title = this.isUpdate ? '修改项目信息' : '新建项目信息'
        this.getPjDetail()
    },
    watch: {
        "queryFrom.price": {
            handler(newly) {
                this.queryFrom.costBudget = newly * this.costing / 100
            }
        },
        'costing': {
            handler(newly) {
                this.queryFrom.costBudget = this.queryFrom.price * newly / 100
            }
        }
    },
    methods: {
        getPjDetail() {
            if (!this.isUpdate) {
                return
            }
            getInfo(this.projectId).then(res => {
                if (res.data.code == 200) {
                    this.queryFrom = res.data.data
                    this.uploader = this.queryFrom.fileList
                    this.costing = parseFloat((this.queryFrom.costBudget / this.queryFrom.price * 100).toFixed(2)) || 0
                }
            })
        },
        //获取配置信息
        getColumn() {
            configDetailList(2).then((res) => {
                let c = res.data.rows.map((it) => {
                    return {
                        label: it.name,
                        value: it.value,
                        shortName: it.commonValue,
                    };
                });
                this.columns = c;
            });
        },
        //地址拼接
        onFinish({ selectedOptions }) {
            this.showA = false;
            this.queryFrom.city = selectedOptions
                .map((option) => option.text)
                .join("/");
        },
        //时间选择
        chooseTime(T) {
            if (T == "start") {
                this.queryFrom.startTime = timeChange(this.currentDate, true)
            } else {
                this.queryFrom.endTime = timeChange(this.currentDate, true)
            }
            this.showB = this.showC = false;
        },
        submitForm() {
            //对已选择的审核人不同的数据进行整合
            // let selectAll = JSON.parse(JSON.stringify(this.flag))
            // this.tempArr.forEach(it => {
            //     selectAll.unshift(it)
            // })
            // this.queryFrom.projectReviewedList = selectAll.map((it, idx) => {
            //     return {
            //         approvalSequence: idx,
            //         userId: it.userId || it.commonValue
            //     }
            // })
            this.isLoading = true
            if (this.isUpdate) {
                infoPut(this.queryFrom).then(res => {
                    if (res.data.code == 200) {
                        this.isLoading = false
                        Toast.success("修改成功");
                        this.$router.replace(this.routerPath)
                    }
                }).catch(() => {
                    this.isLoading = false
                });
            } else {
                infoAdd(this.queryFrom).then((res) => {
                    if (res.data.code == 200) {
                        this.isLoading = false
                        Toast.success("提交成功");
                        this.$router.replace('/pjList');
                    }
                }).catch(() => {
                    this.isLoading = false
                });
            }
        },
        //获取负责人
        getPjPerson(val) {
            this.queryFrom.personInCharge = val.text;
            this.queryFrom.chargeId = val.value
        },

        // //审核流程
        getjudgeList(val) {
            this.tempArr = val
        },
        //获取已选人的列表
        getSecectList() {
            let l = JSON.parse(window.localStorage.getItem("chooseList")) || []
            this.selectList = l.length > 0 ? l : [{ value: -1, avatar: require("../assets/icon/tj_img.png") }]
            if (this.selectList[0].value != -1) {
                this.flag = this.selectList
            }
        },
        //项目类型
        onConfirm(val) {
            this.show = false;
            this.queryFrom.projectTypeName = val.label;
            this.queryFrom.projectType = val.value;
            this.queryFrom.projectNo = val.shortName;
        },
        //校验不通过
        onFailed(errorInfo) {
            Toast.fail(errorInfo.errors[0].message)
        },
        beforeRead(file) {
            if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
            //上传之前校验
            if (file.size > 30 * 1024 * 1024) {
                Toast('只允许上传30M的文件')
                return false
            }
            return true;
        },
        async afterRead(file) {
            //文件读取完成后的回调函数
            file.status = "uploading";
            file.message = "上传中...";
            this.uploading = true;
            let fd = new FormData();
            fd.append("file", file.file);
            await fileUpload(fd).then((res) => {
                if (res.data.code == 200) {
                    let obj = {
                        fileName: res.data.originalName,
                        url: res.data.url,
                    };
                    this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
                    this.uploader[this.uploader.length - 1].fileName =
                        res.data.originalName;
                    this.queryFrom.fileList.push(obj);
                    file.status = "";
                    file.message = ""; //上传状态
                    this.uploading = false;
                } else {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            }).catch(err => {
                if (err) {
                    file.status = "failed";
                    file.message = "上传失败";
                    this.uploading = false;
                }
            });

        },
        deleteFile(file) {
            this.queryFrom.fileList = this.queryFrom.fileList.filter(it => { return it.url !== file.url })
        },
        viewFile(file) {
            if (file.url.indexOf('.jpg') !== -1 || file.url.indexOf('.jpeg') !== -1 || file.url.indexOf('.png') !== -1) {
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: file.url, routerPath: `newPj?projectId=${this.projectId}&route=` + this.routerPath },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 2vh;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;
    font-size: 1rem;
}

/deep/ .van-cell__title {
    -webkit-box-flex: unset;
    -webkit-flex: unset;
    flex: unset;
    text-align: left;
}

/deep/ .van-cell__value {
    text-align: left;
}

.address {
    margin-bottom: unset;
}

.data {
    text-align: left;
    text-indent: 16px;
    margin-bottom: 2vh;
}

.noData {
    display: none;
}
</style>