<template>
    <div class="bk">
        <van-field v-model="person" is-link readonly :label="title" placeholder="请选择" @click="showA = true" class="address"
            :rules="[{ required: true, message: '负责人不能为空' }]" required />
        <van-popup v-model="showA" round position="bottom">
            <van-cascader v-model="cascaderValue" title="请选择" :options="options" @close="showA = false" @change="onChange"
                @finish="onFinish" />
        </van-popup>
    </div>
</template>

<script>
import Vue from 'vue';
import { Popup, Cascader } from 'vant';

Vue.use(Popup);
Vue.use(Cascader)

import { getUserAndDept } from '../api/dept'
export default {
    name: 'selectPerson',
    props: {
        title: {
            typeof: String,
            default: ''
        },
        choosePerson: {
            typeof: String,
            default: ''
        },
    },
    data() {
        return {
            person: '',
            showA: false,
            options: [],
            userInfo: {},
            cascaderValue: '',
            deptId: 0,
            flag: false,
            value: '',
            idList: []//承装id列表
        }
    },
    watch: {
        choosePerson: {
            handler(newly) {
                this.person = newly
            }
        }
    },
    mounted() {
        this.getUserInof()
    },
    methods: {
        getUserInof() {
            getUserAndDept(this.deptId).then(res => {
                let { deptList, userList } = res.data.data

                deptList.forEach(item => {
                    let temp = {
                        text: item.deptName,
                        value: item.deptId,
                        children: [],
                        parentId: item.parentId
                    }
                    this.options.push(temp)
                });
                userList.forEach(it => {
                    let t = {
                        avatar: it.avatar,
                        text: it.name + "(" + it.title + ")",
                        value: it.userId,
                    }
                    this.options.push(t)
                })
            })
        },
        onChange(val) {
            if (this.idList.includes(val.value)) {
                return
            } else {
                this.idList.push(val.value)
            }

            if (Object.prototype.toString.call(val.value) == '[object String]') {
                this.onFinish(val)
                return
            }
            if (val.value == this.value) {
                return
            }
            getUserAndDept(val.value).then(res => {
                if (res.data.code == 200) {
                    this.value = val.value
                    let { deptList, userList } = res.data.data
                    if (!userList) {
                        val.selectedOptions[val.tabIndex].children = [{
                            text: '该部门下没有成员，请返回上级查询',
                            value: '404'
                        }]
                        return
                    }
                    userList.forEach(it => {
                        let t = {
                            avatar: it.avatar,
                            text: it.name + "(" + it.title + ")",
                            value: it.userId,
                        }
                        val.selectedOptions[val.tabIndex].children.push(t)
                    })

                    if (deptList) {
                        deptList.forEach(item1 => {
                            let temp = {
                                text: item1.deptName,
                                value: item1.deptId,
                                children: [],
                                parentId: item1.parentId
                            }
                            val.selectedOptions[val.tabIndex].children.push(temp)
                        });

                    } else {
                        this.onFinish()
                    }
                }
            })
        },
        onFinish(val) {
            let { selectedOptions, tabIndex } = val
            this.showA = false;
            this.userInfo = selectedOptions[tabIndex]
            this.person = this.userInfo.text
            this.$emit('selectPerson', this.userInfo)
        },
    }
}

</script>

<style lang="less" scoped>
.bk {
    width: 100vw;
    background: #fff;
    box-sizing: border-box;
    margin-bottom: 2vh;
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.avatar {
    width: 3rem;
    height: 3rem;
    margin: 0 10px;
}
</style>